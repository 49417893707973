/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The type of visualisation for a text field. Big will be rendered as textarea, small as a general input.
 */
export enum FreeTextInputVisualisation {
    BIG = 'big',
    SMALL = 'small',
}
