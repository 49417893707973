/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum AppLanguage {
    EN = 'en',
    SV = 'sv',
    DE = 'de',
    DA = 'da',
}
