const trans = {
  'accessDeniedPage.header': 'Du saknar tyvärr behörighet till denna sida',
  'accessDeniedPage.helpMessage': 'Vänligen kontrollera länken',
  'da': 'Danish',
  'en': 'English',
  'errors.generalAuthPNRError': 'Detta personnummer kan inte användas för autentisering',
  'errors.generalAuthTokenError': 'Länken kan inte användas för autentisering',
  'errors.invalidOrNoToken': 'Länken är inte aktuell eller otillräcklig',
  'errors.invalidPNRError': 'Felaktigt personnummer eller felaktigt format',
  'errors.noCredsToSaveError': 'Otillräckliga eller ej korrekta uppgifter',
  'errors.noPatientError': 'Ingen information funnen',
  'errors.noPNRError': 'Inget personnummer inskrivet',
  'errors.noUserWithPNR': 'Ingen information hittas för detta personnummer',
  'errors.someDataIsNotValidError': 'Ej korrekta uppgifter',
  'errors.tokenWasAlreadyAnswered': 'Det finns redan ett svar för denna länk',
  'errors.unexpectedError': 'Ett oväntat fel inträffade',
  'errors.unknownError': 'Ett oväntat fel inträffade',
  'firstName': 'Ditt förnamn',
  'form.error.emailFormat': 'Vänligen kontrollera e-post',
  'form.error.mustNotEmpty': 'Fältet kan inte vara tomt',
  'form.error.persNumberFormat': 'Vänligen kontrollera personnumret',
  'form.hint.fieldIsOptional': 'Valfritt',
  'form.hint.mustNotEmpty': 'Obligatorisk',
  'form.placeholder.select': 'Välj ditt svar...',
  'form.placeholder.typeHere': 'Skriv här...',
  'lastName': 'Ditt efternamn',
  'loader.retrievingRecords': 'Kontrollerar anslutning...',
  'notFoundPage.header': 'Det verkar som att sidan inte finns',
  'notFoundPage.helpMessage': 'Vänligen kontrollera länken',
  'patient.search.personalNumberHeading': 'Sök via personnummer',
  'patientEmail': 'Patientens e-post (endast om personnummer saknas)',
  'patientSearch.hint.emailFormat': 'Vänligen säkerställ korrekt format',
  'patientSearch.hint.persNumberFormat': '12 tecken',
  'patientSearch.provideEmailOrPnr': 'Vänligen skriv in personens personnummer eller e-post',
  'personalNumber': 'Patientens personnummer',
  'phone': 'Ditt telefonnummer',
  'relative.relativeForm.heading': 'Berätta om dig själv',
  'relative.relativeForm.headingIfAuth': 'Symtomenkät för',
  'relative.relativeForm.headingIfAuthDescriptionAdditional': 'Personen i fråga kan av olika skäl ha svårt att lämna uppriktiga och/eller utförliga beskrivningar av sitt tillstånd, varför en närståendes perspektiv är av stort värde i bedömningen.',
  'relative.relativeForm.headingIfAuthDescriptionMain1': 'Du har blivit tillfrågad av',
  'relative.relativeForm.headingIfAuthDescriptionMain2': 'att svara på en Symtomenkät i samband med dennes kognitiva bedömning som görs genom appen Minnesmottagningen.',
  'relative.relativeForm.headingSearch': 'Vänligen fyll i nedan för att starta Symtomenkäten',
  'relative.relativeForm.saveButton': 'Starta',
  'startPage.button.getStarted': 'Starta',
  'startPage.button.goToSearch': 'Gå till sök',
  'startPage.heading.linkNotValid': 'Tyvärr är den angivna länken inte längre aktiv',
  'startPage.heading.noQuestionsFromServer': 'Ett fel inträffade',
  'startPage.heading.noQuestionsTip1': 'Försök att ladda om sidan',
  'startPage.heading.noQuestionsTip2': 'Vänligen se över och korrigera personnummer',
  'startPage.heading.searchOption': 'Vänligen se över och korrigera personnummer',
  'startPage.heading.welcome': 'Välkommen till symtomenkäten',
  'startPage.welcomeMessage1': 'Vänligen svara på frågor gällande',
  'successPage.heading': 'Svaren är nu sparade.',
  'successPage.thanksMessage': 'Tack för din återkoppling. Informationen är av stort värde för den fortsatta utredningen.',
  'survey.buttons.back': 'Tillbaka',
  'survey.buttons.next': 'Nästa',
  'survey.buttons.save': 'Skicka',
  'sv': 'Svenska',
  'symptom-survey.answer.child': 'Barn',
  'symptom-survey.answer.difficult-to-discuss-with-patienten': 'Svårt att diskutera detta med patienten',
  'symptom-survey.answer.do-not-know-not-relevant': 'Vet ej / ej relevant',
  'symptom-survey.answer.dont-know-cant-be-assessed': 'Vet inte / kan inte bedöma',
  'symptom-survey.answer.friend': 'Vän',
  'symptom-survey.answer.gradually-transition-in-a-faster-pace': 'Gradvis - snabbare förlopp',
  'symptom-survey.answer.gradually-transition-in-an-insidious': 'Gradvis - på ett smygande sätt',
  'symptom-survey.answer.half-one-year-ago': '1⁄2 - 1 år',
  'symptom-survey.answer.less-than-six-months-ago': 'Mindre än 6 månader',
  'symptom-survey.answer.more-than-five-years-ago': 'mera än 5 år',
  'symptom-survey.answer.no': 'Nej',
  'symptom-survey.answer.often': 'Ofta',
  'symptom-survey.answer.one-two-years-ago': '1 - 2 år',
  'symptom-survey.answer.other-relationship': 'Annan relation',
  'symptom-survey.answer.parent': 'Förälder',
  'symptom-survey.answer.partner': 'Partner',
  'symptom-survey.answer.periodically-returning-symptoms-with-normal-functioning-periods': 'Periodvis återkommande symtom, däremellan fungerande som tidigare',
  'symptom-survey.answer.sibling': 'Syskon',
  'symptom-survey.answer.sometimes': 'Ibland',
  'symptom-survey.answer.somewhat': 'Delvis',
  'symptom-survey.answer.sudden-change-thereafter-gradual-deterioration': 'Plötslig förändring, - därefter stegvis försämring',
  'symptom-survey.answer.sudden-change-thereafter-improved': 'Plötslig förändring, - därefter förbättring',
  'symptom-survey.answer.sudden-change-thereafter-insidious-deterioration': 'Plötslig förändring, - därefter smygande försämring',
  'symptom-survey.answer.sudden-change-thereafter-unchanged': 'Plötslig förändring, - därefter oförändrat',
  'symptom-survey.answer.text': 'Skriv här..',
  'symptom-survey.answer.three-five-years-ago': '3 - 5 år',
  'symptom-survey.answer.two-three-years-ago': '2 - 3 år',
  'symptom-survey.answer.yes': 'Ja',
  'symptom-survey.answer.yes-pronounced-often': 'Ja, uttalat / ofta',
  'symptom-survey.answer.yes-somewhat-sometimes': 'Ja, något / ibland',
  'symptom-survey.question.assessing-distance': '<strong>bedöma avstånd och inta rätt kroppsläge,</strong> exempelvis genom att sätta sig på en stol eller lägga sig i en säng med kroppen rätt placerad',
  'symptom-survey.question.become-distrustful': 'har blivit <strong>misstänksam</strong> och kan exempelvis komma med grundlösa anklagelser, visa obefogad svartsjuka eller känna sig förföljd. Hen kan även på annat sätt ge uttryck för <strong>vanföreställningar</strong>',
  'symptom-survey.question.changed-behavior': '<strong>förändrat sitt beteende.</strong> Detta kan leda till genanta eller problematiska situationer i sällskapslivet eller samlivet',
  'symptom-survey.question.changed-eating-habits': '<strong>förändrat sina matvanor.</strong> Hunger, mättnadskänsla eller val av födoämnen är annorlunda än tidigare och bordsskicket kan vara försämrat',
  'symptom-survey.question.changed-facial-expressions': 'har <strong>annorlunda ansiktsuttryck.</strong> Minspelet är mindre spontant och varierat, vilket gör att det blivit svårare att avläsa känsloläge och reaktioner',
  'symptom-survey.question.changed-sense-of-humor': '<strong>förändrat sinne för humor.</strong> Har svårt att förstå skämt, misstolkar, tar åt sig eller skämtar själv på ett sätt som är olikt hen. Kan verka road och upprymd utan att det tycks finnas orsak',
  'symptom-survey.question.changed-sleeping-habits': 'har <strong>förändrat sina sovvanor,</strong> exempelvis om hen sover sämre, sover mera eller sover vid andra tider på dygnet än tidigare',
  'symptom-survey.question.changed-walking': 'har <strong>förändrat sitt sätt att gå</strong> och stegen är korta, trippande eller mera klumpiga',
  'symptom-survey.question.clumsier-or-trembling': 'är <strong>fumligare</strong> eller har blivit mera <strong>darrhänt</strong>',
  'symptom-survey.question.counting': '<strong>räkna</strong> och förstå sin ekonomi och pengars värde, använda betalkort, betala räkningar och kontrollera kontoutdrag',
  'symptom-survey.question.difficulty-remembering-what-was-said': '<strong>komma ihåg</strong> vad hen alldeles nyligen gjort',
  'symptom-survey.question.evident-specific-event-symptoms': 'Känner Du/Ni till någon <strong>speciell händelse</strong> som kan sättas i samband med symtomen eller närmare ange <strong>tidpunkten</strong> då symtomen började visa sig?',
  'symptom-survey.question.experienced-impaired-awareness': '<strong>försämrad insikt,</strong> t ex om de problem som symtomen leder till för den egna personen eller för andra',
  'symptom-survey.question.feeling-more-down': 'är mera <strong>nedstämd</strong> och kan vara dyster, grubblande, ledsen med nedsatt ”gnista” eller livslust',
  'symptom-survey.question.finding-direction': '<strong>hitta rätt väg</strong> vid bilfärd, promenad, köprunda i varuhus eller toalettbesök hemma hos bekanta',
  'symptom-survey.question.finding-words': '<strong>hitta rätt ord.</strong> Försöker ofta finna ett annat sätt att förklara vad hen menar eller talar osammanhängande',
  'symptom-survey.question.first-noticed-symptoms': '<strong>Vilket/vilka symtom</strong> av de som markerats i enkäten var det/de som <strong>först</strong> observerades?',
  'symptom-survey.question.first-symptoms-noticed': '<strong>För hur länge sedan</strong> noterades de första symtomen av de som markerats i enkäten?',
  'symptom-survey.question.following-instructions-and-manuals': '<strong>följa instruktioner och bruksanvisningar</strong> med gott resultat, som vid hopsättning av möbler eller vid montering av apparater/redskap',
  'symptom-survey.question.hard-to-manage-symptoms': 'Berättar patienten att hen <strong>upplever något symtom som särskilt svårt?</strong>',
  'symptom-survey.question.has-been-confused': 'har stundtals eller periodvis blivit <strong>förvirrad.</strong> Exempelvis genom att hastigt blivit mera oklar i tankarna, fått svårare att orientera sig i tid och rum, visat ett förändrat beteende och fått svårt att komma till ro',
  'symptom-survey.question.has-hallucinations': 'har <strong>hallucinationer.</strong> Exempelvis kan hen se, höra eller känna sådant som inte existerar i verkligheten, men som hen upplever tydligt',
  'symptom-survey.question.how-related-with-patienten': '<strong>Hur är du släkt med eller kopplad till patienten?</strong>',
  'symptom-survey.question.impaired-balance': 'har <strong>försämrad balans</strong> och faller lättare omkull',
  'symptom-survey.question.impaired-judgement': '<strong>försämrat omdöme.</strong> Kan göra sådant som innebär risker eller måste redas ut och är <strong>mindre noggrann,</strong> exempelvis vid skötsel av hemmet eller den personliga hygienen',
  'symptom-survey.question.increased-difficulty-getting-involved': '<strong>svårare att engagera sig och visa medkänsla</strong> i det som tidigare varit viktigt, både vad gäller familjen och samhället i stort. Känsloreaktionerna är svagare, otydligare eller avvikande',
  'symptom-survey.question.increased-difficulty-planning': '<strong>svårare att planera,</strong> exempelvis gällande att sköta hemmet, göra inköp eller välja lämpliga kläder',
  'symptom-survey.question.increased-difficulty-remembering-what-day-it-is': 'veta <strong>vilken dag</strong> det är',
  'symptom-survey.question.increased-difficulty-remembering-what-has-happened': '<strong>komma ihåg</strong> det som ligger längre tillbaka i tiden och kan ha svårt att minnas i vilken ordning olika minnesvärda händelser inträffat',
  'symptom-survey.question.increased-difficulty-remembering-what-time-it-is': 'veta <strong>vilken tid på dygnet</strong> det är',
  'symptom-survey.question.increased-difficulty-remembering-what-to-do': '<strong>komma ihåg</strong> vad som ska göras eller kommer att inträffa, exempelvis beställa tid för något eller uppmärksamma en högtidsdag',
  'symptom-survey.question.increased-difficulty-remembering-what-was-done': '<strong>komma ihåg</strong> vad som alldeles nyligen sagts',
  'symptom-survey.question.increased-difficulty-remembering-without-being-reminded': '<strong>komma ihåg</strong> utan att bli påmind eller använda minneslappar, almanacka eller dylikt',
  'symptom-survey.question.increased-difficulty-stopping': 'har svårare att hejda sig då hen blir arg och kan bli <strong>hotfull</strong> eller <strong>våldsam</strong>',
  'symptom-survey.question.increased-difficulty-taking-initiative': '<strong>förändrad initiativförmåga.</strong> Har exempelvis svårare att ta sig för sysslor som tidigare varit rutiner eller har blivit överaktiv på ett sätt som skapar problem eller oordning',
  'symptom-survey.question.interpreting-maps': '<strong>tolka kartor, planskisser, mönster eller ritningar</strong>',
  'symptom-survey.question.light-hearted': 'är mera <strong>lättrörd</strong> ( gråtmild/”blödig” ) eller <strong>växlar humör hastigt</strong> utan tydlig orsak',
  'symptom-survey.question.missed-toilet': '<strong>har svårare att känna när hen behöver gå på toaletten,</strong> vilket kan leda till att hen kissar på sig',
  'symptom-survey.question.moments-of-absence': 'har korta <strong>stunder av frånvaro,</strong> där hen inte reagerar på tilltal eller ”fastnar” i en rörelse eller aktivitet på ett sätt som är svårt att bryta',
  'symptom-survey.question.more-sensitive-to-stress': 'är mera <strong>stresskänslig</strong> och kan dra sig undan sällskap eller intensiva aktiviteter. Har ett ökat behov av att göra en sak i taget.',
  'symptom-survey.question.more-taciturn': 'blivit mer <strong>fåordig</strong> och <strong>talet flyter sämre.</strong> Använder <strong>samma ord eller uttryck</strong> gång på gång',
  'symptom-survey.question.more-worried': 'är mera <strong>orolig, rastlös</strong> eller har blivit <strong>rädd</strong> för vissa situationer',
  'symptom-survey.question.most-noticeable-symptoms': '<strong>Vilket/vilka symtom</strong> av de som markerats i enkäten är det/de som nu märks <strong>tydligast?</strong>',
  'symptom-survey.question.moves-slower': '<strong>rör sig långsammare</strong>',
  'symptom-survey.question.moves-stiffer': '<strong>rör sig stelare och mera osmidigt</strong>',
  'symptom-survey.question.nuanced-opinions': '<strong>mera onyanserade åsikter,</strong> värderingar och synpunkter. Har svårare att ta till sig argument och <strong>resonerar ologiskt</strong>',
  'symptom-survey.question.patienten-agree-with-symptom-survey': '<strong>Håller patienten med om att de symtom Du/Ni angivit i enkäten stämmer?</strong>',
  'symptom-survey.question.performing-everyday-tasks': '<strong>utföra vardagliga praktiska sysslor</strong> trots tillräcklig rörlighet, t ex påklädning, hushållsarbete, handarbete, bilkörning eller användning av olika apparater såsom kaffebryggare, tvättmaskin, fjärrkontroll, mobil eller dator',
  'symptom-survey.question.reacts-slowly': '<strong>reagerar långsammare.</strong> Hinner inte med att öka tempot och göra det som behövs då något händer plötsligt',
  'symptom-survey.question.recognizing-objects': '<strong>känna igen</strong> vardagliga föremål, bilder, symboler eller ansikten på välkända personer. Hitta rätt ikon på dator eller mobil',
  'symptom-survey.question.space-for-comments': '<strong>Plats för kommentarer</strong>',
  'symptom-survey.question.speaks-less': '<strong>talar otydligare</strong> för att uttalet blivit sluddrigt eller för att rösten blivit svagare',
  'symptom-survey.question.stress-for-the-family': 'Har några av de markerade symtomen lett till <strong>påfrestningar för anhöriga?</strong>',
  'symptom-survey.question.symptoms-variations-day-to-day': '<strong>Varierar symtomen från dag till dag?</strong>',
  'symptom-survey.question.thinks-slower': '<strong>tänker trögare</strong> och behöver fundera längre innan hen svarar',
  'symptom-survey.question.understanding-heard-read': '<strong>förstå vad hen hör eller läser,</strong> t ex innehållet i meddelanden, tidningar eller program på radio/TV/mobil/dator',
  'symptom-survey.question.way-symptoms-develop': '<strong>På vilket sätt har symtomen utvecklats?</strong>',
  'symptom-survey.question.writing': '<strong>skriva,</strong> exempelvis genom att skriva brev, meddelanden eller fylla i blanketter',
  'symptom-survey.results.evident-specific-event-symptoms': 'Specifik händelse',
  'symptom-survey.results.first-noticed-symptoms': 'Första symtom',
  'symptom-survey.results.hard-to-manage-symptoms': 'Symtom svåra att hantera',
  'symptom-survey.results.most-noticeable-symptoms': 'Mest märkbara symtom',
  'symptom-survey.results.patienten-agree-with-symptom-survey': 'Patienten instämmer',
  'symptom-survey.results.stress-for-the-family': 'Symtom som är påfrestande för andra',
  'symptom-survey.section.mental-reactions-section.subtitle': 'Patienten ...',
  'symptom-survey.section.mental-reactions-section.title': 'Vanliga psykiska reaktioner och andra associerade symtom vid sjukdomar som påverkar hjärnan',
  'symptom-survey.section.other-questions-section.subtitle': 'Patienten ...',
  'symptom-survey.section.other-questions-section.title': 'Övriga, kompletterande/förtydligande frågor om observerade symtom',
  'symptom-survey.section.subcortical-regions-of-brain-section.subtitle': 'Patienten ...',
  'symptom-survey.section.subcortical-regions-of-brain-section.title': 'Vanliga symtom vid nedsatt funktion i djupare delar av hjärnan, under hjärnbarken',
  'symptom-survey.section.symptoms-of-impaired-function-in-frontal-lobes-of-brain.subtitle': 'Patienten har...',
  'symptom-survey.section.symptoms-of-impaired-function-in-frontal-lobes-of-brain.title': 'Vanliga symtom vid nedsatt funktion i hjärnans pannlober',
  'symptom-survey.section.symptoms-of-impaired-function-in-parietal-section.subtitle': 'Patienten har fått svårare att...',
  'symptom-survey.section.symptoms-of-impaired-function-in-parietal-section.title': 'Vanliga symtom vid nedsatt funktion i hjärnans hjäss- och tinninglober',
  'symptom-survey.section.symptoms-of-impaired-memory-section.subtitle': 'Patienten har svårare att...',
  'symptom-survey.section.symptoms-of-impaired-memory-section.title': 'Vanliga symtom på minnesstörning',
  'thePatient': 'patienten',
  'thePatientCapitalized': 'Patienten',
  'word.behaviour': 'beteende',
  'word.of': 'av',
  'word.pleaseWait': 'Vänligen vänta',
  'word.section': 'Sektion',
};
 export default trans;