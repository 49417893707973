const trans = {
  'accessDeniedPage.header': 'Ups, noget gik galt',
  'accessDeniedPage.helpMessage': 'Kontroller, at linket er korrekt',
  'da': 'Danish',
  'en': 'engelsk',
  'errors.generalAuthPNRError': 'Det oplyste personnummer kan ikke bruges til godkendelse',
  'errors.generalAuthTokenError': 'Det angivne link kan ikke bruges til godkendelse',
  'errors.invalidOrNoToken': 'Det angivne link er ikke gyldigt eller indeholder ikke nødvendige data',
  'errors.invalidPNRError': 'Ugyldigt personnummer angivet',
  'errors.noCredsToSaveError': 'Angivne legitimationsoplysninger er ikke gyldige eller indeholder ikke nødvendige data',
  'errors.noPatientError': 'Ingen data fundet',
  'errors.noPNRError': 'Intet personnummer angivet',
  'errors.noUserWithPNR': 'Der er ingen patient med det oplyste personnummer',
  'errors.someDataIsNotValidError': 'Nogle af de angivne data er ikke gyldige',
  'errors.tokenWasAlreadyAnswered': 'Det ser ud til, at undersøgelsen allerede blev besvaret via dette link',
  'errors.unexpectedError': 'Der opstod en uventet fejl',
  'errors.unknownError': 'Der opstod en ukendt fejl',
  'firstName': 'Dit fornavn',
  'form.error.emailFormat': 'Tjek venligst formatet på e-mail',
  'form.error.mustNotEmpty': 'Feltet må ikke være tomt',
  'form.error.persNumberFormat': 'Tjek venligst længden og formatet på personnummeret',
  'form.hint.fieldIsOptional': 'Valgfrit',
  'form.hint.mustNotEmpty': 'Obligatorisk',
  'form.placeholder.select': 'Vælg dit svar...',
  'form.placeholder.typeHere': 'Skriv her...',
  'lastName': 'Dit efternavn',
  'loader.retrievingRecords': 'Henter poster og kontrollerer data...',
  'notFoundPage.header': 'Det ser ud til, at den side, du anmodede om, ikke eksisterer',
  'notFoundPage.helpMessage': 'Kontroller, at linket er korrekt',
  'patient.search.personalNumberHeading': 'Søg på patientens personnummer',
  'patientEmail': 'Patientens e-mail (kun hvis personnummer mangler)',
  'patientSearch.hint.emailFormat': 'Angiv e-mail med korrekt format',
  'patientSearch.hint.persNumberFormat': '12 tegn',
  'patientSearch.provideEmailOrPnr': 'Angiv venligst personens personnummer eller e-mail',
  'personalNumber': 'Patientens personnummer',
  'phone': 'Dit telefonnummer',
  'relative.relativeForm.heading': 'Fortæl os om dig selv',
  'relative.relativeForm.headingIfAuth': 'Symptomundersøgelse vedr.',
  'relative.relativeForm.headingIfAuthDescriptionAdditional': 'Den udredte kan af forskellige årsager opleve vanskeligheder med at give en korrekt og fuldstændig beskrivelse af tilstanden og symptomerne. Derfor har de pårørendes perspektiv stor værdi i vurderingen.',
  'relative.relativeForm.headingIfAuthDescriptionMain1': 'Du er blevet bedt om at udfylde en Symptomundersøgelse vedr.',
  'relative.relativeForm.headingIfAuthDescriptionMain2': 'som en del af en kognitiv vurdering udført gennem The Memory Clinic app.',
  'relative.relativeForm.headingSearch': 'Angiv venligst data for at starte undersøgelsen',
  'relative.relativeForm.saveButton': 'Fortsæt',
  'startPage.button.getStarted': 'Kom i gang',
  'startPage.button.goToSearch': 'Gå til søg',
  'startPage.heading.linkNotValid': 'Det ser ud til, at det link, du angav, ikke er gyldigt',
  'startPage.heading.noQuestionsFromServer': 'Der opstod en fejl under hentning af data til undersøgelse',
  'startPage.heading.noQuestionsTip1': 'Prøv at opdatere siden',
  'startPage.heading.noQuestionsTip2': 'Tjek og rediger det angivne personnummer, og prøv igen',
  'startPage.heading.searchOption': 'Du kan også udfylde undersøgelsen med patientens personnummer',
  'startPage.heading.welcome': 'Velkommen til Symptoundersøgelsessiden',
  'startPage.welcomeMessage1': 'Svar venligst på spørgsmål vedr.',
  'successPage.heading': 'Undersøgelsesresultater er blevet gemt uden problemer!',
  'successPage.thanksMessage': 'Tak for dine svar! Oplysningerne er af stor værdi for undersøgelsen.',
  'survey.buttons.back': 'Tilbage',
  'survey.buttons.next': 'Næste',
  'survey.buttons.save': 'Send resultater',
  'sv': 'Svensk',
  'symptom-survey.answer.child': 'Barn',
  'symptom-survey.answer.difficult-to-discuss-with-patienten': 'Svært at diskutere med patienten',
  'symptom-survey.answer.do-not-know-not-relevant': 'Ved ikke/ikke relevant',
  'symptom-survey.answer.dont-know-cant-be-assessed': 'Ved ikke/kan ikke vurderes',
  'symptom-survey.answer.friend': 'Ven',
  'symptom-survey.answer.gradually-transition-in-a-faster-pace': 'Gradvis - i et hurtigere tempo',
  'symptom-survey.answer.gradually-transition-in-an-insidious': 'Gradvis - i et snigende og langsommere tempo',
  'symptom-survey.answer.half-one-year-ago': '1⁄2 - 1 år siden',
  'symptom-survey.answer.less-than-six-months-ago': 'For mindre end 6 måneder siden',
  'symptom-survey.answer.more-than-five-years-ago': 'for mere end 5 år siden',
  'symptom-survey.answer.no': 'Nej',
  'symptom-survey.answer.often': 'Ofte',
  'symptom-survey.answer.one-two-years-ago': '1-2 år siden',
  'symptom-survey.answer.other-relationship': 'Andet forhold',
  'symptom-survey.answer.parent': 'Forælder',
  'symptom-survey.answer.partner': 'Partner',
  'symptom-survey.answer.periodically-returning-symptoms-with-normal-functioning-periods': 'Periodisk tilbagevendende symptomer, med normale funktionsperioder',
  'symptom-survey.answer.sibling': 'Søskend',
  'symptom-survey.answer.sometimes': 'Sommetider',
  'symptom-survey.answer.somewhat': 'Noget',
  'symptom-survey.answer.sudden-change-thereafter-gradual-deterioration': 'Pludselig ændring, - derefter gradvis forværring',
  'symptom-survey.answer.sudden-change-thereafter-improved': 'Pludselig ændring, - derefter forbedret',
  'symptom-survey.answer.sudden-change-thereafter-insidious-deterioration': 'Pludselig ændring, - derefter snigende forværring',
  'symptom-survey.answer.sudden-change-thereafter-unchanged': 'Pludselig ændring, - derefter uændret',
  'symptom-survey.answer.text': 'Skriv her...',
  'symptom-survey.answer.three-five-years-ago': '3-5 år siden',
  'symptom-survey.answer.two-three-years-ago': '2-3 år siden',
  'symptom-survey.answer.yes': 'Ja',
  'symptom-survey.answer.yes-pronounced-often': 'Ja, udtalt / ofte',
  'symptom-survey.answer.yes-somewhat-sometimes': 'Ja, noget / sommetider',
  'symptom-survey.question.assessing-distance': '<strong>vurdere afstand og komme i den korrekte kropsposition,</strong> fx ved at sidde på en stol eller ligge i en seng med kroppen korrekt placeret',
  'symptom-survey.question.become-distrustful': 'er blevet<strong> mistroisk</strong> og kan for eksempel komme med falske anklager, vise uberettiget jalousi eller opleve en følelse af at blive forfulgt. Han/hun kan også give udtryk for<strong> vrangforestillinger</strong> på andre måder',
  'symptom-survey.question.changed-behavior': '<strong>ændret adfærd.</strong> Dette kan føre til pinlige eller problematiske situationer i det sociale liv eller i samlivet',
  'symptom-survey.question.changed-eating-habits': '<strong>ændrede spisevaner.</strong> Sult, mæthed eller valg af mad er anderledes end før, og bordskik kan være forringet',
  'symptom-survey.question.changed-facial-expressions': '<strong>har ændret ansigtsudtryk.</strong> Ansigtsudtryk er mindre spontane og varierede, hvilket gør det sværere at aflæse følelsesmæssig tilstand og reaktioner',
  'symptom-survey.question.changed-sense-of-humor': '<strong>ændret sans for humor.</strong> Har svært ved at forstå vittigheder, misfortolker, tager tingene mere personligt eller joker på en anderledes måde. Kan virke underholdt og opstemt uden nogen klar grund',
  'symptom-survey.question.changed-sleeping-habits': '<strong>har ændret sovevaner,</strong> for eksempel at sove mere eller sove på et andet tidspunkt af dagen i forhold til før',
  'symptom-survey.question.changed-walking': '<strong>har ændret måden at gå på</strong> og skridtene er kortere, trippende eller mere klodsede',
  'symptom-survey.question.clumsier-or-trembling': '<strong>er mere klodset</strong> eller er ryster mere<strong> skælvende</strong>',
  'symptom-survey.question.counting': '<strong>tæller</strong> og forstå hans/hendes økonomi, som for eksempel at veksle penge, betale regninger, bruge checks og betalinlgskort, tjekke kontoudtog og forstå værdien af penge',
  'symptom-survey.question.difficulty-remembering-what-was-said': '<strong>havde svært ved at huske</strong> hvad der for nylig blev sagt',
  'symptom-survey.question.evident-specific-event-symptoms': '<strong>skrive</strong> , for eksempel at skrive breve, beskeder eller udfylde skemaer',
  'symptom-survey.question.experienced-impaired-awareness': '<strong>oplevet nedsat bevidsthed,</strong> for eksempel de problemer, som hans/hendes symptomer fører til, eller hvordan de påvirker andre mennesker',
  'symptom-survey.question.feeling-more-down': 'føler<strong> mere nede,</strong> dyster, grublende med tab af "gnist" eller livlighed',
  'symptom-survey.question.finding-direction': '<strong>at finde den rigtige vej</strong> når man kører bil, går, handler i stormagasiner eller er på toiletbesøg hjemme hos bekendte',
  'symptom-survey.question.finding-words': '<strong>finde de rigtige ord.</strong> Forsøger ofte at finde en anden måde at forklare, hvad han/hun mener eller taler usammenhængende',
  'symptom-survey.question.first-noticed-symptoms': '<strong>Hvilke af de nævnte symptomer</strong> i undersøgelsen ville du sige <strong> først blev bemærket?</strong>',
  'symptom-survey.question.first-symptoms-noticed': '<strong>Hvornår blev de første af de symptomer, der er nævnt i undersøgelsen, først bemærket?</strong>',
  'symptom-survey.question.following-instructions-and-manuals': '<strong>følge instruktioner og manualer</strong> med gode resultater, såsom ved samling af møbler eller installation af hårde hvidevarer/værktøj',
  'symptom-survey.question.hard-to-manage-symptoms': '<strong>Har patienten fortalt dig, om nogle symptomer har været særligt svære at håndtere?</strong>',
  'symptom-survey.question.has-been-confused': '<strong>er blevet forvirret</strong> til tider eller periodisk. For eksempel ved hurtigt at have uklare tanker, opleve orienteringsbesvær i tid og rum, ved at vise en ændring i adfærd og føle sig urolig',
  'symptom-survey.question.has-hallucinations': '<strong>har hallucinationer.</strong> For eksempel kan han/hun se, høre eller føle ting, der ikke eksisterer i virkeligheden, men som han/hun tydeligt følte som en oplevelse',
  'symptom-survey.question.how-related-with-patienten': '<strong>Hvordan er du i familie med eller kender patienten?</strong>',
  'symptom-survey.question.impaired-balance': '<strong>har svækket balance</strong> og falder lettere omkuld',
  'symptom-survey.question.impaired-judgement': '<strong>nedsat dømmekraft.</strong> Kan gøre ting, der medfører risici eller skal ordnes og er<strong> mindre forsigtig,</strong> for eksempel ved pasning af hjemmet eller personlig hygiejne',
  'symptom-survey.question.increased-difficulty-getting-involved': '<strong>havde i stigende grad svært ved at blive involveret og vise medfølelse</strong> i det, der tidligere har været vigtigt, både i forhold til familien og samfundet som helhed. De følelsesmæssige reaktioner er svagere, uklare eller afvigende',
  'symptom-survey.question.increased-difficulty-planning': '<strong>havde i stiggende grad  problemer med at planlægge,</strong> fx med hensyn til at passe hjemmet, købe ind eller vælge passende tøj',
  'symptom-survey.question.increased-difficulty-remembering-what-day-it-is': 'havde i stigende grad svært ved at huske<strong> hvilken dag det er</strong>',
  'symptom-survey.question.increased-difficulty-remembering-what-has-happened': '<strong>havde i stigende grad svært ved at huske,</strong> hvad der er sket i fortiden og kan have svært ved at huske rækkefølgen, hvori forskellige mindeværdige begivenheder fandt sted',
  'symptom-survey.question.increased-difficulty-remembering-what-time-it-is': 'havde i stigende grad svært ved at huske,<strong> hvilken tid på dagen det er</strong>',
  'symptom-survey.question.increased-difficulty-remembering-what-to-do': '<strong>havde i stigende grad svært ved at huske,</strong> hvad man skal gøre, eller hvad der skal ske, såsom at lave en aftale eller huske en særlig lejlighed',
  'symptom-survey.question.increased-difficulty-remembering-what-was-done': '<strong>havde i stigende grad svært ved at huske,</strong> hvad der blev gjort for nylig',
  'symptom-survey.question.increased-difficulty-remembering-without-being-reminded': '<strong>havde i stigende grad svært ved at huske</strong> uden at blive mindet om det eller bruge huskesedler, kalender eller lignende',
  'symptom-survey.question.increased-difficulty-stopping': 'har i stigende grad svært ved at stoppe, når man bliver vred og kan være<strong> truende</strong> eller<strong> voldsom</strong>',
  'symptom-survey.question.increased-difficulty-taking-initiative': '<strong>havde i stigende grad svært ved at tage initiativ.</strong> Det er for eksempel sværere at påtage sig opgaver, der tidligere har været rutiner, eller er blevet overaktive på en måde, der skaber problemer eller rod',
  'symptom-survey.question.interpreting-maps': '<strong>fortolkning af kort, tegninger eller mønstre</strong>',
  'symptom-survey.question.light-hearted': 'er mere<strong> let berørt (let til tårer/"nærtagende" </strong> eller <strong> har pludselige humørsvingninger</strong> uden en klar årsag',
  'symptom-survey.question.missed-toilet': '<strong>har sværere ved at finde ud af, hvornår der er behov for toiletbesøg,</strong> hvilket kan resultere i at han/hun tisser i bukserne',
  'symptom-survey.question.moments-of-absence': 'har kort<strong> øjeblikke af fravær</strong> hvor han/hun ikke reagerer på tiltale eller "fastlåses" i en bevægelse eller aktivitet på en måde, der er svær at stoppe',
  'symptom-survey.question.more-sensitive-to-stress': 'er mere<strong> følsom over for stress</strong> og kan trække sig fra socialt samvær eller intense aktiviteter. Har et øget behov for at gøre én ting ad gangen',
  'symptom-survey.question.more-taciturn': 'blive mere<strong> fåmælt</strong> og<strong> talen flyder mindre</strong> . Bruger<strong> de samme ord eller sætninger</strong> igen og igen',
  'symptom-survey.question.more-worried': 'er<strong> mere bekymret, rastløs</strong> og kan evt.<strong> føle sig bange</strong> i visse situationer',
  'symptom-survey.question.most-noticeable-symptoms': '<strong>Hvilke af de nævnte symptomer</strong> i undersøgelsen ville du sige har været<strong> mest iøjnefaldende?</strong>',
  'symptom-survey.question.moves-slower': '<strong>bevæger sig langsommere</strong>',
  'symptom-survey.question.moves-stiffer': '<strong>bevæger sig på en stivere og mere ufleksibel måde</strong>',
  'symptom-survey.question.nuanced-opinions': '<strong>mindre nuancerede meninger,</strong> værdier og synspunkter. Har i stigende grad svært ved at opfatte argumenter og<strong> ræsonnerer ulogisk</strong>',
  'symptom-survey.question.patienten-agree-with-symptom-survey': '<strong>Er patienten enig i de punkter, du har angivet i symptomundersøgelsen?</strong>',
  'symptom-survey.question.performing-everyday-tasks': '<strong>udfører daglige praktiske opgaver</strong> tilstrækkeligt på trods af mobilitet såsom påklædning, husarbejde, bilkørsel eller brug af forskellige husholdningsapparater',
  'symptom-survey.question.reacts-slowly': '<strong>reagerer langsommere.</strong> Har svært ved at øge tempoet og gøre det, der skal til, når der pludselig sker noget',
  'symptom-survey.question.recognizing-objects': '<strong>genkende</strong> hverdagsgenstande, billeder, symboler eller ansigter af velkendte personer',
  'symptom-survey.question.space-for-comments': '<strong>Plads til kommentarer</strong>',
  'symptom-survey.question.speaks-less': '<strong>taler mindre</strong> tydeligt, fordi talen er blevet sløret eller fordi stemmen er blevet svagere',
  'symptom-survey.question.stress-for-the-family': 'Har nogen af de fremhævede symptomer forårsaget nogen<strong> stress for familien eller de pårørende?</strong>',
  'symptom-survey.question.symptoms-variations-day-to-day': '<strong>Varierer symptomerne fra dag til dag?</strong>',
  'symptom-survey.question.thinks-slower': '<strong>tænker langsommere</strong> og skal tænke længere, før der svares',
  'symptom-survey.question.understanding-heard-read': '<strong>forstå, hvad der høres eller læses,</strong> for eksempel indholdet af breve, aviser eller programmer på radio/tv',
  'symptom-survey.question.way-symptoms-develop': '<strong>På hvilken måde udviklede symptomerne sig?</strong>',
  'symptom-survey.question.writing': '<strong>skrivning</strong> , for eksempel at skrive breve, beskeder eller udfylde formularer',
  'symptom-survey.results.evident-specific-event-symptoms': 'Specifik hændelse',
  'symptom-survey.results.first-noticed-symptoms': 'Første symptomer',
  'symptom-survey.results.hard-to-manage-symptoms': 'Symptomer, der er svære at håndtere',
  'symptom-survey.results.most-noticeable-symptoms': 'Mest mærkbare symptomer',
  'symptom-survey.results.patienten-agree-with-symptom-survey': 'Patienten er enig i beskrivelsen',
  'symptom-survey.results.stress-for-the-family': 'Symptomer er stressende for andre',
  'symptom-survey.section.mental-reactions-section.subtitle': 'Patienten...',
  'symptom-survey.section.mental-reactions-section.title': 'Almindelige mentale reaktioner og andre associerede symptomer på sygdomme, der påvirker hjernen',
  'symptom-survey.section.other-questions-section.subtitle': 'Patienten...',
  'symptom-survey.section.other-questions-section.title': 'Andre supplerende spørgsmål vedrørende observerede symptomer',
  'symptom-survey.section.subcortical-regions-of-brain-section.subtitle': 'Patienten...',
  'symptom-survey.section.subcortical-regions-of-brain-section.title': 'Almindelige symptomer på nedsat funktion i de subkortikale områder af hjernen',
  'symptom-survey.section.symptoms-of-impaired-function-in-frontal-lobes-of-brain.subtitle': 'Patienten har...',
  'symptom-survey.section.symptoms-of-impaired-function-in-frontal-lobes-of-brain.title': 'Almindelige symptomer på nedsat funktion i hjernens frontallapper',
  'symptom-survey.section.symptoms-of-impaired-function-in-parietal-section.subtitle': 'Patienten har svært ved...',
  'symptom-survey.section.symptoms-of-impaired-function-in-parietal-section.title': 'Almindelige symptomer på nedsat funktion i hjernens parietal- og temporallapper',
  'symptom-survey.section.symptoms-of-impaired-memory-section.subtitle': 'Patienten har...',
  'symptom-survey.section.symptoms-of-impaired-memory-section.title': 'Almindelige symptomer på nedsat hukommelsesfunktion',
  'thePatient': 'patienten',
  'thePatientCapitalized': 'patienten',
  'word.behaviour': 'adfærd',
  'word.of': 'af',
  'word.pleaseWait': 'Vent venligst',
  'word.section': 'Afsnit',
};
 export default trans;