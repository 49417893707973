/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ManageHlthyEventDTO } from '../models/ManageHlthyEventDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WebhookService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Webhook for healthy
     * @param xWebhookSignature
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public handleHealthyWebhook(
        xWebhookSignature: string,
        requestBody: ManageHlthyEventDTO,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/webhook/hlthy',
            headers: {
                'x-webhook-signature': xWebhookSignature,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
